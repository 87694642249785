import React, { useEffect } from 'react';

import Hero from '../components/Hero';
import Brands from '../components/Brands';
import Projects from '../components/Projects';

import HomePagdData from '../data/HomePagdData.json';

export default function Home() {
  const {
    hero,
    socialBtns,
    brands,
    about,
    projects,
    service,
    experience,
    testimonial,
    contact,
  } = HomePagdData;
  return (
    <>
      <Hero data={hero} socialData={socialBtns} />
      <Projects data={projects} />
    </>
  );
}
